import Container1PisoB from "@/widgets/Container/Templates/Container1PisoB";
import { Dummy } from "./style";

const RevistaElle = (props) => {
  const { isPreview = false } = props;
  return (
    isPreview ? <Dummy>Revista Elle</Dummy> : (
    <Container1PisoB {...props} positionProperties={[{templateNews:"TwoxOneVisual"}]} titleTarget='_blank'/>
  ));
};
export default RevistaElle;