import styled from "styled-components";
import { Font } from "@/shared/Constants";

export const Dummy = styled.div`
  ${Font.sansSemiBold}
  font-size: 25px;
  height: 400px;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`